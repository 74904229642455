import React, { Suspense } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { GuardedRoute, GuardProvider } from 'react-router-guards';
import { guards } from './guards';
import { routes } from './routes';

const AuthMensagem = React.lazy(() => import('@/components/auth/mensagem'));

export const Router = (() => {
  return (
    <BrowserRouter>
      <GuardProvider
        guards={guards}
        loading={() => <></>}
        error={() => <></>}
        ignoreGlobal
      >
        <Suspense fallback={<></>}>
          <Switch>
            {routes.map(({ component: Component, exact, meta, path }) => {
              return (
                <GuardedRoute
                  key={path}
                  render={(props) => <Component {...props} />}
                  path={path}
                  meta={meta}
                  exact={exact}
                />
              );
            })}
            <GuardedRoute
              path="*"
              component={() => (
                <AuthMensagem
                  title="Oops!"
                  message="Não conseguimos encontrar o que você procura"
                />
              )}
            />
          </Switch>
        </Suspense>
      </GuardProvider>
    </BrowserRouter>
  );
}) as React.FC;
