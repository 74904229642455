import { Alerta } from '@/generated/server';
import React, { useEffect, useMemo, useState } from 'react';
import { SocketContext } from './context';
import { SocketClient } from './socket';

export const SocketProvider = (({ children }) => {
  const socket = useMemo(() => new SocketClient(), []);
  const [alertas, setAlertas] = useState<Alerta[]>([]);

  useEffect(() => {
    socket.on('alert_stable', ({ tipo, equipamentoInfo, estabelecimentoInfo }) => {
      setAlertas((alertas) =>
        alertas.filter((a) => {
          return (
            a.tipo != tipo ||
            a.equipamentoInfo.id !== equipamentoInfo.id ||
            a.estabelecimentoInfo.id != estabelecimentoInfo.id
          );
        })
      );
    });

    socket.on('alert_instable', (alerta) => {
      setAlertas((alertas) => [...alertas, alerta]);
    });

    socket.on('connect', () => {
      setAlertas([]);
    });
  }, []);

  return (
    <SocketContext.Provider
      value={{
        socket,
        alertas
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}) as React.FC;
