import { SocketEvent } from '@/generated/server';
import { SOCKET_URL } from '../constants';

export class SocketClient {
  private socket = io(SOCKET_URL, {
    transports: ['websocket', 'pooling'],
    rememberUpgrade: true,
    upgrade: true
  });

  // Define os listeners padrões
  constructor() {
    this.on('connect', () => {
      console.debug(`Conectado ao socket como: ${this.socket.id}`);
    });

    this.on('disconnect', () => {
      console.debug('Desconectado do socket...');
    });

    this.on('connect_error', console.debug);

    this.on('connect_failed', console.debug);
  }

  // Os mesmos métodos do socket, só que é tipado pelo typescript
  on = <T extends keyof SocketEvent>(
    event: T,
    callback: (data: SocketEvent[T]) => void
  ): void => {
    this.socket.on(event, callback);
  };

  once = <T extends keyof SocketEvent>(
    event: T,
    callback: (data: SocketEvent[T]) => void
  ): void => {
    this.socket.once(event, callback);
  };

  off = <T extends keyof SocketEvent>(
    event: T,
    callback: (data: SocketEvent[T]) => void
  ): void => {
    this.socket.off(event, callback);
  };

  emit = <T extends keyof SocketEvent>(
    event: keyof T & string,
    data: SocketEvent[T]
  ): void => {
    this.socket.emit(event, data);
  };
}
