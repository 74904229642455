import { restClient } from '@/lib/http';
import { GuardFunction } from 'react-router-guards';

export const loginGuard: GuardFunction = async (to, from, next) => {
  const { data } = await restClient.getUsuario();

  if (!to.meta.public && !data) {
    next.redirect({
      pathname: '/entrar',
      state: {
        from: from?.location.pathname
      }
    });
    return;
  }

  next();
};
