import '@/assets/plugins/nucleo/css/nucleo.css';
import '@/lib/charts';
import { Router } from '@/routes';
import '@/styles/argon-dashboard-react.scss';
import 'font-awesome/css/font-awesome.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { register } from 'timeago.js';
import pt from 'timeago.js/lib/lang/pt_BR';
import { SocketProvider } from './lib/socket/provider';

(async () => {
  register('pt-br', pt);

  const geo = await import('@/lib/geolocation');
  const app = document.getElementById('app');

  // Espera o carregamento da página
  await new Promise<void>((res) =>
    ReactDOM.render(
      <SocketProvider>
        <Router />
      </SocketProvider>,
      app,
      res
    )
  );

  geo.listenDom();
})();
