export function logErrors(...errors: string[]) {
  for (const error of errors) {
    console.error(error);
  }
}

export function logWebErrors(
  ...webErrors: {
    errors: any[];
    hasErrors: boolean;
  }[]
) {
  for (const { hasErrors, errors } of webErrors) {
    if (hasErrors)
      for (const error of errors) {
        console.error(error);
      }
  }
}
