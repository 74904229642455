import { lazy } from 'react';

type RouteInfo = {
  path: string;
  component: any;
  exact: boolean;
  meta: {
    /** Se pessoas não autenticadas tem permissão, strict é literalmente somente não autenticadas */
    public?: boolean | 'strict';
  };
};

export const routes: RouteInfo[] = [
  {
    path: '/',
    exact: true,
    component: lazy(() => import('@/pages/index')),
    meta: {}
  },
  {
    path: '/estabelecimentos',
    exact: true,
    component: lazy(() => import('@/pages/estabelecimentos/index')),
    meta: {}
  },
  {
    path: '/estabelecimentos/novo',
    exact: true,
    component: lazy(() => import('@/pages/estabelecimentos/novo')),
    meta: {}
  },
  {
    path: '/estabelecimentos/:id',
    exact: true,
    component: lazy(() => import('@/pages/estabelecimentos/[id]')),
    meta: {}
  },
  {
    path: '/equipamentos/',
    exact: true,
    component: lazy(() => import('@/pages/equipamentos/index')),
    meta: {}
  },
  {
    path: '/equipamentos/novo',
    exact: true,
    component: lazy(() => import('@/pages/equipamentos/novo')),
    meta: {}
  },
  {
    path: '/equipamentos/:imei',
    exact: true,
    component: lazy(() => import('@/pages/equipamentos/[imei]')),
    meta: {}
  },
  {
    path: '/cliente/criar',
    exact: true,
    component: lazy(() => import('@/pages/cliente/criar')),
    meta: {
      public: true
    }
  },
  {
    path: '/usuarios/acesso',
    exact: true,
    component: lazy(() => import('@/pages/usuarios/acesso')),
    meta: {}
  },
  {
    path: '/usuarios/integracoes',
    exact: true,
    component: lazy(() => import('@/pages/usuarios/integracoes')),
    meta: {}
  },
  {
    path: '/usuarios/lista',
    exact: true,
    component: lazy(() => import('@/pages/usuarios/lista')),
    meta: {}
  },
  {
    path: '/alertas',
    exact: true,
    component: lazy(() => import('@/pages/alertas')),
    meta: {}
  },
  {
    path: '/alertas/:id',
    exact: true,
    component: lazy(() => import('@/pages/alertas/[id]')),
    meta: {}
  },
  {
    path: '/mensagem',
    exact: true,
    component: lazy(() => import('@/pages/mensagem')),
    meta: {
      public: true
    }
  },
  {
    path: '/entrar',
    exact: true,
    component: lazy(() => import('@/pages/entrar')),
    meta: {
      public: true
    }
  }
];
