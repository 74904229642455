import { Charts } from '@/types/chart';

export const Options = {
  // TODO: Ver se essa propriedade faz diferença pois o Charts.Options fala que não precisa
  mode: 'light',
  fonts: {
    base: 'Open Sans'
  },
  colors: {
    gray: {
      100: '#f6f9fc',
      200: '#e9ecef',
      300: '#dee2e6',
      400: '#ced4da',
      500: '#adb5bd',
      600: '#8898aa',
      700: '#525f7f',
      800: '#32325d',
      900: '#212529'
    },
    theme: {
      default: '#172b4d',
      primary: '#5e72e4',
      secondary: '#f4f5f7',
      info: '#11cdef',
      success: '#2dce89',
      danger: '#f5365c',
      warning: '#fb6340'
    },
    black: '#12263F',
    white: '#FFFFFF',
    transparent: 'transparent'
  }
};

export const multiLineChartOptions: Charts.Options = {
  scales: {
    yAxes: [
      {
        gridLines: {
          color: Options.colors.gray[900],
          zeroLineColor: Options.colors.gray[900]
        },
        ticks: {
          callback: (a) => (Number(a) % 1 === 0 ? a : null)
        }
      }
    ]
  },
  tooltips: {
    callbacks: {
      label: function (item, data) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return data.datasets![item.datasetIndex!]!.label + ' ' + item.yLabel;
      }
    }
  }
};
