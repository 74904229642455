import { HttpClient, RestApplicationClient } from '@/generated/server';
import { API_URL, MAX_TIMEOUT } from '@/lib/constants';
import { logErrors } from '@/lib/log';
import bindAll from 'auto-bind';
import Axios, { AxiosInstance, Method } from 'axios';
import { AxiosCacheInstance, useCache } from 'axios-cache-interceptor';

type RestClientKey = keyof RestApplicationClient;
// eslint-disable-next-line @typescript-eslint/ban-types
type RestMethod = RestApplicationClient[RestClientKey] & Function;
type CacheDeletionRecord = Partial<Record<RestClientKey, RestMethod[]>>;

export class CachedAxiosClient extends RestApplicationClient {
  readonly axios: AxiosInstance;
  readonly client: AxiosCacheInstance;

  /**
   * Mapa com lista de requests e as remoções do cache que tem que ser feitas.
   *
   * @example
   *   // Significa que ao fazer a request entrar(),
   *   // o cache do getUsuario() será removido.
   *   const cacheDeletion = { entrar: [this.getUsuario] };
   */
  private cacheDeletion: CacheDeletionRecord = {
    entrar: [this.getUsuario],
    postEquipamento: [this.getEquipamentos],
    deleteEquipamentoByImei: [this.getEquipamentos]
  };

  constructor() {
    super({ request: (o) => this.request(o) });

    // Força todos os métodos a user o `this` como essa instância.
    bindAll(this);

    this.axios = Axios.create({
      validateStatus: (s) => s >= 200 && s < 500,
      timeout: MAX_TIMEOUT,
      withCredentials: true,
      baseURL: API_URL
    });

    this.client = useCache(this.axios, {
      methods: ['get', 'post'],
      interpretHeader: true,
      etag: true,
      modifiedSince: true,
      ttl: 1000 * 60 * 10 // 10 segundos
    });

    this.reflectRestMethods();
  }

  request: HttpClient['request'] = async ({
    method,
    url,
    queryParams,
    data,
    options
  }) => {
    try {
      const response = await this.client.request({
        method: method as Method,
        params: queryParams,
        data: data,
        url: url,
        id: options?.id,
        ...options
      });

      return response.data;
    } catch (e: any) {
      logErrors(`Ocorreu um erro durante uma request para ${url}`, e);

      return {
        errors: [e.message],
        hasErrors: true
      };
    }
  };

  /**
   * Sobrescreve todas as funções da classe super. Ao uma função ser chamada, passa um id
   * junto com as o objeto opções. Esse id equivale ao nome do método
   */
  private reflectRestMethods = () => {
    const keys = Reflect.ownKeys(super.constructor.prototype);

    for (const n of keys) {
      // Ignorar esses métodos
      if (['constructor', 'request'].includes(String(n))) {
        continue;
      }

      const name = n as RestClientKey;
      const method: any = this[name];

      // Somente função
      if (typeof method != 'function') {
        continue;
      }

      this[name] = ((...props: any) => {
        // Remove a ultima propriedade, a '{ option: O }'.
        const lastProp = props.pop();

        // Filtra somente objetos ou undefined no caso de argumento opcional.
        if (lastProp !== undefined && typeof lastProp != 'object') {
          const params = [...props, lastProp];
          return method(...params);
        }

        const caches = this.cacheDeletion[name];

        if (!caches) {
          return method(...props, { id: name, ...lastProp });
        }

        const promises = caches.map((method) => {
          return this.client.storage.remove(method.name);
        });

        // Espera a remoção de todos os itens do cache e depois resolve o método
        return Promise.all(promises).then(() => {
          return method(...props, { id: name, ...lastProp });
        });
      }) as any;
    }
  };
}
