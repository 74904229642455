/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.26.723 on 2021-11-05 11:22:31.

import { CacheRequestConfig } from 'axios-cache-interceptor';
export interface HttpClient {
  request<R>(requestConfig: {
    method: string;
    url: string;
    queryParams?: any;
    data?: any;
    copyFn?: (data: R) => R;
    options?: CacheRequestConfig<never>;
  }): RestResponse<R>;
}

export class RestApplicationClient {
  constructor(protected httpClient: HttpClient) {}

  /**
   * HTTP GET /api/alertas/stable Java method:
   * br.com.tecnauvac.controller.AlertaController.getAlertasStable
   */
  getAlertasStable(
    queryParams?: { limit?: string; cursor?: string },
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<Alerta[]>> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`api/alertas/stable`,
      queryParams: queryParams,
      options: options
    });
  }

  /**
   * HTTP GET /api/alertas/{id} Java method:
   * br.com.tecnauvac.controller.AlertaController.getAlertaById
   */
  getAlertaById(
    id: string,
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<Alerta>> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`api/alertas/${id}`,
      options: options
    });
  }

  /** HTTP POST /api/auth/entrar Java method: br.com.tecnauvac.controller.AuthController.entrar */
  entrar(
    input: LoginInput,
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<boolean>> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`api/auth/entrar`,
      data: input,
      options: options
    });
  }

  /** HTTP POST /api/auth/registrar Java method: br.com.tecnauvac.controller.AuthController.registrar */
  registrar(
    input: RegisterInput,
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<boolean>> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`api/auth/registrar`,
      data: input,
      options: options
    });
  }

  /** HTTP POST /api/auth/sair Java method: br.com.tecnauvac.controller.AuthController.sair */
  sair(options?: CacheRequestConfig<never>): RestResponse<WebResponse<boolean>> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`api/auth/sair`,
      options: options
    });
  }

  /** HTTP GET /api/clientes Java method: br.com.tecnauvac.controller.ClienteController.getUsuarios */
  getUsuarios(options?: CacheRequestConfig<never>): RestResponse<WebResponse<Usuario[]>> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`api/clientes`,
      options: options
    });
  }

  /** HTTP POST /api/clientes Java method: br.com.tecnauvac.controller.ClienteController.postCliente */
  postCliente(
    input: ClienteInput,
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<PrimeiroUsuario>> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`api/clientes`,
      data: input,
      options: options
    });
  }

  /**
   * HTTP GET /api/equipamentos Java method:
   * br.com.tecnauvac.controller.EquipamentoController.getEquipamentos
   */
  getEquipamentos(
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<EquipamentoComStatus[]>> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`api/equipamentos`,
      options: options
    });
  }

  /**
   * HTTP POST /api/equipamentos Java method:
   * br.com.tecnauvac.controller.EquipamentoController.postEquipamento
   */
  postEquipamento(
    input: EquipamentoInput,
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<number>> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`api/equipamentos`,
      data: input,
      options: options
    });
  }

  /**
   * HTTP DELETE /api/equipamentos/{imei} Java method:
   * br.com.tecnauvac.controller.EquipamentoController.deleteEquipamentoByImei
   */
  deleteEquipamentoByImei(
    imei: string,
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<boolean>> {
    return this.httpClient.request({
      method: 'DELETE',
      url: uriEncoding`api/equipamentos/${imei}`,
      options: options
    });
  }

  /**
   * HTTP GET /api/equipamentos/{imei} Java method:
   * br.com.tecnauvac.controller.EquipamentoController.getEquipamentoByImei
   */
  getEquipamentoByImei(
    imei: string,
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<EquipamentoComStatus>> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`api/equipamentos/${imei}`,
      options: options
    });
  }

  /**
   * HTTP PUT /api/equipamentos/{imei} Java method:
   * br.com.tecnauvac.controller.EquipamentoController.putEquipamentoByImei
   */
  putEquipamentoByImei(
    imei: string,
    input: EditEquipamentoInput,
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<boolean>> {
    return this.httpClient.request({
      method: 'PUT',
      url: uriEncoding`api/equipamentos/${imei}`,
      data: input,
      options: options
    });
  }

  /**
   * HTTP GET /api/equipamentos/{imei}/alertas Java method:
   * br.com.tecnauvac.controller.EquipamentoController.getAlertasFromEquipamento
   */
  getAlertasFromEquipamento(
    imei: string,
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<Alerta[]>> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`api/equipamentos/${imei}/alertas`,
      options: options
    });
  }

  /**
   * HTTP GET /api/equipamentos/{imei}/estabelecimento Java method:
   * br.com.tecnauvac.controller.EquipamentoController.getEstabelecimentoFromEquipamento
   */
  getEstabelecimentoFromEquipamento(
    imei: string,
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<Estabelecimento>> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`api/equipamentos/${imei}/estabelecimento`,
      options: options
    });
  }

  /**
   * HTTP GET /api/equipamentos/{imei}/payloads Java method:
   * br.com.tecnauvac.controller.EquipamentoController.getPayloadsFromEquipamento
   */
  getPayloadsFromEquipamento(
    imei: string,
    queryParams?: { limit?: string; cursor?: string },
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<Payload[]>> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`api/equipamentos/${imei}/payloads`,
      queryParams: queryParams,
      options: options
    });
  }

  /**
   * HTTP GET /api/estabelecimentos Java method:
   * br.com.tecnauvac.controller.EstabelecimentosController.getEstabelecimentos
   */
  getEstabelecimentos(
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<Estabelecimento[]>> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`api/estabelecimentos`,
      options: options
    });
  }

  /**
   * HTTP POST /api/estabelecimentos Java method:
   * br.com.tecnauvac.controller.EstabelecimentosController.postEstabelecimento
   */
  postEstabelecimento(
    input: EstabelecimentoInput,
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<number>> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`api/estabelecimentos`,
      data: input,
      options: options
    });
  }

  /**
   * HTTP GET /api/estabelecimentos/{id} Java method:
   * br.com.tecnauvac.controller.EstabelecimentosController.getEstabelecimentoById
   */
  getEstabelecimentoById(
    id: string,
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<EstabelecimentoComStatus>> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`api/estabelecimentos/${id}`,
      options: options
    });
  }

  /**
   * HTTP GET /api/estabelecimentos/{id}/payloads Java method:
   * br.com.tecnauvac.controller.EstabelecimentosController.getPayloadsByEstabelecimento
   */
  getPayloadsByEstabelecimento(
    id: string,
    queryParams?: { limit?: string; cursor?: string },
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<Payload[]>> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`api/estabelecimentos/${id}/payloads`,
      queryParams: queryParams,
      options: options
    });
  }

  /**
   * HTTP GET /api/integracoes/token Java method:
   * br.com.tecnauvac.controller.IntegracaoController.getIntegracaoToken
   */
  getIntegracaoToken(
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<string>> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`api/integracoes/token`,
      options: options
    });
  }

  /**
   * HTTP POST /api/integracoes/whatsapp Java method:
   * br.com.tecnauvac.controller.IntegracaoController.associarWhatsapp
   */
  associarWhatsapp(
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<boolean>> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`api/integracoes/whatsapp`,
      options: options
    });
  }

  /**
   * HTTP DELETE /api/integracoes/whatsapp Java method:
   * br.com.tecnauvac.controller.IntegracaoController.desassociarWhatsapp
   */
  desassociarWhatsapp(
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<boolean>> {
    return this.httpClient.request({
      method: 'DELETE',
      url: uriEncoding`api/integracoes/whatsapp`,
      options: options
    });
  }

  /** HTTP POST /api/payloads Java method: br.com.tecnauvac.controller.PayloadController.postPayload */
  postPayload(
    queryParams?: { pl?: string; tk?: string },
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<Equipamento>> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`api/payloads`,
      queryParams: queryParams,
      options: options
    });
  }

  /**
   * HTTP GET /api/payloads/search Java method:
   * br.com.tecnauvac.controller.PayloadController.searchPayloads
   */
  searchPayloads(
    queryParams?: { imei?: string; start?: string; end?: string },
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<Payload[]>> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`api/payloads/search`,
      queryParams: queryParams,
      options: options
    });
  }

  /**
   * HTTP GET /api/payloads/{id} Java method:
   * br.com.tecnauvac.controller.PayloadController.getPayloadById
   */
  getPayloadById(
    id: string,
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<Payload>> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`api/payloads/${id}`,
      options: options
    });
  }

  /** HTTP GET /api/usuarios/eu Java method: br.com.tecnauvac.controller.UsuarioController.getUsuario */
  getUsuario(options?: CacheRequestConfig<never>): RestResponse<WebResponse<Usuario>> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`api/usuarios/eu`,
      options: options
    });
  }

  /**
   * HTTP GET /api/usuarios/eu/cliente Java method:
   * br.com.tecnauvac.controller.UsuarioController.getCliente
   */
  getCliente(options?: CacheRequestConfig<never>): RestResponse<WebResponse<Cliente>> {
    return this.httpClient.request({
      method: 'GET',
      url: uriEncoding`api/usuarios/eu/cliente`,
      options: options
    });
  }

  /**
   * HTTP POST /api/usuarios/{id}/master Java method:
   * br.com.tecnauvac.controller.UsuarioController.setMaster
   */
  setMaster(
    id: string,
    queryParams?: { value?: string },
    options?: CacheRequestConfig<never>
  ): RestResponse<WebResponse<boolean>> {
    return this.httpClient.request({
      method: 'POST',
      url: uriEncoding`api/usuarios/${id}/master`,
      queryParams: queryParams,
      options: options
    });
  }
}

export interface Alerta {
  dataVigenciaFinal: string;
  dataVigenciaInicial: string;
  equipamentoInfo: EquipamentoInfo;
  estabelecimentoInfo: EstabelecimentoNome;
  id: number;
  payloadAtual: Payload;
  payloadEnd: Payload;
  payloadStart: Payload;
  tipo: TipoAlerta;
}

export interface Cliente {
  brasao: string;
  dataVigenciaInicial: string;
  estabelecimentos: Estabelecimento[];
  id: number;
  pessoa: Pessoa;
}

export interface ClienteInput extends PessoaInput {
  masterName: string;
  masterSenha: string;
}

export interface EditEquipamentoInput {
  descricao: string;
  temperaturaInternaMax: number;
  temperaturaInternaMin: number;
}

export interface Equipamento {
  dataVigenciaInicial: string;
  descricao: string;
  id: number;
  imei: number;
  temperaturaInternaMax: number;
  temperaturaInternaMin: number;
}

export interface EquipamentoComStatus {
  dataVigenciaInicial: string;
  descricao: string;
  id: number;
  imei: number;
  status: Payload;
  temperaturaInternaMax: number;
  temperaturaInternaMin: number;
}

export interface EquipamentoInfo {
  dataVigenciaInicial: string;
  descricao: string;
  id: number;
  imei: number;
  temperaturaInternaMax: number;
  temperaturaInternaMin: number;
}

export interface EquipamentoInput extends EditEquipamentoInput {
  estabelecimentoId: number;
  imei: number;
}

export interface Estabelecimento {
  dataVigenciaInicial: string;
  endereco: string;
  equipamentos: Equipamento[];
  id: number;
  latitude: number;
  longitude: number;
  nome: string;
}

export interface EstabelecimentoComStatus {
  cliente: Cliente;
  dataVigenciaInicial: string;
  endereco: string;
  equipamentos: EquipamentoComStatus[];
  id: number;
  latitude: number;
  longitude: number;
  nome: string;
}

export interface EstabelecimentoInput {
  endereco: string;
  latitude: number;
  longitude: number;
  nome: string;
}

export interface EstabelecimentoNome {
  id: number;
  latitude: number;
  longitude: number;
  nome: string;
}

export interface ImeiInput {
  imei: string;
}

export interface ImeiPayloadSearch {
  end: string;
  imei: number;
  start: string;
}

export interface InputError {
  field: string;
  message: string;
}

export interface LimitCursorInput {
  cursor: string;
  limit: number;
}

export interface LoginInput {
  login: string;
  senha: string;
}

export interface MensagemEnviada {
  alerta: Alerta;
  dataConfirmacao: string;
  dataEnvio: string;
  id: number;
  meioDeEnvio: string;
  payload: Payload;
  tipo: TipoAlerta;
  usuario: Usuario;
}

export interface Payload {
  dataHora: string;
  energia: boolean;
  id: number;
  imei: number;
  nivelBateria: number;
  temperaturaExterna: number;
  temperaturaInterna: number;
}

export interface PayloadSearchInput extends LimitCursorInput {
  imei: number;
}

export interface Pessoa {
  celular: string;
  dataVigenciaInicial: string;
  documento: string;
  email: string;
  endereco: string;
  id: number;
  municipio: string;
  nome: string;
  telegram: string;
  tipoPessoa: TipoPessoa;
  uf: UF;
  whatsapp: string;
}

export interface PessoaInput {
  celular: string;
  documento: string;
  email: string;
  endereco: string;
  municipio: string;
  nome: string;
  tipoPessoa: TipoPessoa;
  uf: UF;
}

export interface PrimeiroUsuario {
  clienteId: number;
  login: string;
  senha: string;
}

export interface RegisterInput extends PessoaInput {
  idCliente: number;
  login: string;
  senha: string;
}

export interface SocketEvent {
  alert_instable: Alerta;
  alert_stable: Alerta;
  connect: void;
  connect_error: any;
  connect_failed: any;
  disconnect: void;
}

export interface Usuario {
  dataVigenciaInicial: string;
  id: number;
  login: string;
  master: number;
  pessoa: Pessoa;
}

export interface WebResponse<T> {
  data: T;
  errors: any[];
  hasErrors: boolean;
}

export type RestResponse<R> = Promise<R>;

export const enum TipoAlerta {
  BATTERY_ERR = 'BATTERY_ERR',
  TEMP_INTERNA_MAX_ERR = 'TEMP_INTERNA_MAX_ERR',
  TEMP_INTERNA_MIN_ERR = 'TEMP_INTERNA_MIN_ERR',
  MAX_TIMEOUT = 'MAX_TIMEOUT',
  ENERGY_ERR = 'ENERGY_ERR'
}

export const enum TipoPessoa {
  F = 'F',
  J = 'J'
}

export const enum UF {
  AC = 'AC',
  AL = 'AL',
  AM = 'AM',
  AP = 'AP',
  BA = 'BA',
  CE = 'CE',
  DF = 'DF',
  ES = 'ES',
  GO = 'GO',
  MA = 'MA',
  MG = 'MG',
  MS = 'MS',
  MT = 'MT',
  PA = 'PA',
  PB = 'PB',
  PE = 'PE',
  PI = 'PI',
  PR = 'PR',
  RJ = 'RJ',
  RN = 'RN',
  RO = 'RO',
  RR = 'RR',
  RS = 'RS',
  SC = 'SC',
  SE = 'SE',
  SP = 'SP',
  TO = 'TO'
}

function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
  let result = '';
  for (let i = 0; i < substitutions.length; i++) {
    result += template[i];
    result += encodeURIComponent(substitutions[i]);
  }
  result += template[template.length - 1];
  return result;
}
