import { AxiosRequestConfig } from 'axios';

/** Backend api url */
export const API_URL = process.env.API_URL || '';

/** Backend socket url */
export const SOCKET_URL = process.env.SOCKET_URL || '';

/** Google Maps Api KEY */
export const MAPS_API_KEY = process.env.MAPS_API_KEY || '';

/** Tempo máximo de resposta da api */
export const MAX_TIMEOUT = 10000; // 10 Segundos

/** Data atual */
export const LOADED_TIMESTAMP = new Date();

export const APP_ENV = (process.env.APP_ENV as 'prod' | 'dev' | 'next') || 'dev';

export const SMT_COLOR =
  APP_ENV === 'next' ? '#4F9D43' : APP_ENV === 'dev' ? '#439D82' : '#436D9C';

export const DIA_EM_MS = 1000 * 60 * 60 * 24;

export const AXIOS_OPTIONS: AxiosRequestConfig = {
  validateStatus: (s) => s >= 200 && s < 500,
  timeout: MAX_TIMEOUT,
  withCredentials: true,
  baseURL: API_URL
};
